import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import { accountReducer } from "./account/acountRedux";
import { productReducer } from "./products/productReducer";
import { procedureReducer } from "./procedures/procedureReducer";
import { companyReducer } from "./companies/companyReducer";
import { listReducer } from "./listRedux";
import { Dispatch } from "react";
import { peopleReducer } from "./peoples/peoplesReducer";
import { departmentReducer } from "./departments/departmentReducer";
import { trainingReducer } from "./trainings/trainingReducer";
import { locationReducer } from "./locations/locationReducer";
import { incidentReducer } from "./incidents/incidentReducer";
import { procedureJobReducer } from "./procedureJob/procedureReducer";
import { commonReducer } from "./common/commonReducer";
import { nonConformanceReducer } from "./nonConformance/nonConformanceReducer";
import { tabReducer } from "./tabs/tabReducer";
import { documentHistoryReducer } from "./documents/documentHistory.reducer";
import { exceptionReducer } from "./exceptions/exception.reducer";
import { trainingMatrixReducer } from "./trainingMatrix/training-matrix.reducer";
import { auditLogReducer } from "./auditChangeLog/auditChangeLog.reducer";
import { complaintReducer } from "./complaints/complaint.reducer";
import { pricebookReducer } from "./pricebook/pricebookReducer";
import { settingsReducer } from "./Settings/settingsReducer";
import { rackReducer } from "./racks/rackReducer";
import { VisitorReducer } from "./Visitor/visitorReducer";
import { EmailHistoryReducer } from "./EmailHistory/emailHistoryReducer";
import { purchaseOrderReducer } from "./purchaseOrder/poReducer";
import { ProductBatchCodeReducer } from "./ProductBatchCode/productBatchCodeReducer";
import { ErrorLogReducer } from "./errorLog/errorLogReducer";
import { configurableTypeReducer } from "./configurableTypes/configurableTypeReducer";
import { unitConversionReducer } from "./unitConversions/UnitConversionReducer";
import { FoodItemReducer } from "./foodItems/FoodItemReducer";
import { goodsInProcessReducer } from "./goodsinprocess/goodsInProcessReducer";
import { downTimeReasonReducer } from "./DownTimeReason/downTimeReasonReducer";
import { recipeProcessReducer } from "./recipeProcess/RecipeProcessReducer";
import { customerReducer } from "./customer/customerReducer";
import { salesOrderReducer } from "./SalesOrder/salesOrderReducer";
import { complaintCategoryReducer } from "./complaints/complaintcategory.reducer";
import { AccidentReducer } from "./accident/accidentAction";

export interface RootAction extends Action {
  payload: any;
}

export interface IListType {
  pageNo: number;
  pageSize: number;
  sortData: string;
  filters: any[];
}

const combineReducer = combineReducers({
  user: accountReducer,
  product: productReducer,
  procedure: procedureReducer,
  people: peopleReducer,
  department: departmentReducer,
  company: companyReducer,
  training: trainingReducer,
  location: locationReducer,
  procedureJob: procedureJobReducer,
  ProductBatchCode: ProductBatchCodeReducer,
  nonConformance: nonConformanceReducer,
  incident: incidentReducer,
  accident: AccidentReducer,
  SalesOrder: salesOrderReducer,
  customer: customerReducer,
  downTimeReason: downTimeReasonReducer,
  foodItem: FoodItemReducer,
  tab: tabReducer,
  documentHistory: documentHistoryReducer,
  exceptions: exceptionReducer,
  trainingMatrix: trainingMatrixReducer,
  auditChangeLog: auditLogReducer,
  complaint: complaintReducer,
  pricebook: pricebookReducer,
  settings: settingsReducer,
  rack: rackReducer,
  listState: listReducer,
  common: commonReducer,
  visitor: VisitorReducer,
  emailHistory: EmailHistoryReducer,
  purchaseOrder: purchaseOrderReducer,
  errorLog: ErrorLogReducer,
  configurableType: configurableTypeReducer,
  unitConversion: unitConversionReducer,
  goodsInProcess: goodsInProcessReducer,
  recipeProcess: recipeProcessReducer,
  complaintCategory: complaintCategoryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "[Account] Logout user success") {
    state = undefined;
  }
  return combineReducer(state, action);
};

// const root = createReducer()

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch | Dispatch<any>;
export type RootState = ReturnType<typeof combineReducer>;
