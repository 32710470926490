import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";
import { formatFilterForQueryParam } from "src/common/service";

export class ConfigurableTypeDataAccess {
  saveConfigurableType = (
    payload: any,
    classType: "COUTY" | "MUNIT" | "PODRS" | "TBCAT" | "INCTP"
  ) => {
    return commonDataAccess.postData(
      `configurableTypes/save/${classType}`,
      payload
    );
  };
  saveCheckCategory = (payload: any) => {
    let classType = "TBCAT";
    payload["classType"] = "TBCAT";
    return commonDataAccess.postData(
      `configurableTypes/save/${classType}`,
      payload
    );
  };
  deleteCountry = (id: any, productId: any) => {
    return commonDataAccess.deleteData("configurableTypes/delete-country", {
      id: id,
      productId: productId,
    });
  };
  deleteConfigurableType = (ids: any) => {
    return commonDataAccess.deleteData("configurableTypes/delete", {
      ids: ids,
    });
  };
  getUOMList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    // console.log(params.complaintStatus);
    return commonDataAccess.getData("configurableTypes/uom/getall", parameters);
  };
  getUnitConversionDropdown = (uomId) => {
    return commonDataAccess.getData(
      "configurableTypes/get/unitconversionsForDropdown",
      { uomId: uomId }
    );
  };
  getById = (uomId) => {
    return commonDataAccess.getData("configurableTypes/getById", {
      uomId: uomId,
    });
  };
  getSelectListItem = (
    classType: "COUTY" | "MUNIT" | "PODRS" | "RCPCT" | "INCTP"
  ) => {
    return commonDataAccess.getData("configurableTypes/getall", {
      type: classType,
    });
  };
  getUOMListItemByProductId = (productId: any) => {
    return commonDataAccess.getData("configurableTypes/getallUOMByProduct", {
      productIds: productId,
    });
  };
  getPurchaseOrderDeletionReasons = () => {
    return commonDataAccess.getData(
      "configurableTypes/get/purchase-order-deletion-reason"
    );
  };
  savePurchaseOrderDeletionReasons = (payload: any) => {
    return commonDataAccess.postData(
      "configurableTypes/save/purchase-order-deletion-reason",
      payload
    );
  };
  DeletePurchaseOrderDeletionReason = (id: any) => {
    return commonDataAccess.deleteData(
      "configurableTypes/delete/purchase-order-deletion-reason",
      { id }
    );
  };
}

export default new ConfigurableTypeDataAccess();
