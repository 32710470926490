import { Action } from "redux";
import { createAction, createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import settingsDataAccess from "src/dataAccess/settings/settingsDataAccess";
import { AnyARecord } from "dns";
import configurableTypeDataaccess from "src/dataAccess/configurableTypes/configurableType.dataaccess";

export const LoadingData = createAction<boolean>("[Settings] Loading data");
export const GetChecksettingsSuccess = createAction<any>(
  "[Settings] Get Check settings success"
);
export const SaveChecksettingsSuccess = createAction<any>(
  "[Settings] Save Check settings success"
);
export const GetChecksettings = createAsyncThunk<any, any>(
  "[Settings] Get Check settings",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getChecksettings(parameter)
      .then((res) => {
        thunkAPI.dispatch(GetChecksettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetAuditsettingsSuccess = createAction<any>(
  "[Settings] Get Audit settings success"
);
export const SaveAuditsettingsSuccess = createAction<any>(
  "[Settings] Save Audit settings success"
);
export const GetAuditsettings = createAsyncThunk<any, any>(
  "[Settings] Get Audit settings",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getAuditSettings().then((res) => {
      thunkAPI.dispatch(GetAuditsettingsSuccess(res));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);
export const GetDashboardSettingsSuccess = createAction<any>(
  "[Settings] Get Dashboard settings success"
);
export const SaveDashboardSettingsSuccess = createAction<any>(
  "[Settings] Save Dashboard settings success"
);
export const GetDispatchSettingsSuccess = createAction<any>(
  "[Settings] Get Dispatch settings success"
);
export const SaveDispatchSettingsSuccess = createAction<any>(
  "[Settings] Save Dispatch settings success"
);
export const GetDashboardSettings = createAsyncThunk<any, any>(
  "[Settings] Get Dashboard settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getDashboardSettings()
      .then((res) => {
        thunkAPI.dispatch(GetDashboardSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);
export const GetDispatchSettings = createAsyncThunk<any, any>(
  "[Settings] Get Dispatch settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getDispatchSettings()
      .then((res) => {
        thunkAPI.dispatch(GetDispatchSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);
export const GetDocumentationSettingsSuccess = createAction<any>(
  "[Settings] Get Documentation settings success"
);
export const SaveDocumentationSettingsSuccess = createAction<any>(
  "[Settings] Save Documentation settings success"
);
export const GetDocumentationSettings = createAsyncThunk<any, any>(
  "[Settings] Get Documentation settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getDocumentationSettings()
      .then((res) => {
        thunkAPI.dispatch(GetDocumentationSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetEmailSettingsSuccess = createAction<any>(
  "[Settings] Get Email settings success"
);
export const SaveEmailSettingsSuccess = createAction<any>(
  "[Settings] Save Email settings success"
);
export const GetEmailSettings = createAsyncThunk<any, any>(
  "[Settings] Get Email settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getEmailSettings().then((res) => {
      thunkAPI.dispatch(GetEmailSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));
      return res;
    });
    return result;
  }
);

export const GetComplaintSettingsSuccess = createAction<any>(
  "[Settings] Get Complaint settings success"
);
export const SaveComplaintSettingsSuccess = createAction<any>(
  "[Settings] Save Complaint settings success"
);
export const GetComplaintSettings = createAsyncThunk<any, any>(
  "[Settings] Get Complaint settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getComplaintSettings()
      .then((res) => {
        thunkAPI.dispatch(GetComplaintSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));
        return res;
      });
    return result;
  }
);

export const GetExportPathSettingsSuccess = createAction<any>(
  "[Settings] Get Export Path settings success"
);
export const SaveExportPathSettingsSuccess = createAction<any>(
  "[Settings] Save Export Path settings success"
);
export const GetExportPathSettings = createAsyncThunk<any, any>(
  "[Settings] Get Export Path settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getExportPathSettings()
      .then((res) => {
        thunkAPI.dispatch(GetExportPathSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetFilterSettingsSuccess = createAction<any>(
  "[Settings] Get Filter settings success"
);
export const SaveFilterSettingsSuccess = createAction<any>(
  "[Settings] Save Filter settings success"
);
export const GetFilterSettings = createAsyncThunk<any, any>(
  "[Settings] Get Filter settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getFilterSettings().then((res) => {
      thunkAPI.dispatch(GetFilterSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);

export const GetLabourSettingsSuccess = createAction<any>(
  "[Settings] Get Labour settings success"
);
export const SaveLabourSettingsSuccess = createAction<any>(
  "[Settings] Save Labour settings success"
);
export const GetLabourSettings = createAsyncThunk<any, any>(
  "[Settings] Get Labour settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getLabourSettings().then((res) => {
      thunkAPI.dispatch(GetLabourSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);

export const GetLogoutSettingsSuccess = createAction<any>(
  "[Settings] Get Logout settings success"
);
export const SaveLogoutSettingsSuccess = createAction<any>(
  "[Settings] Save Logout settings success"
);
export const GetLogoutSettings = createAsyncThunk<any, any>(
  "[Settings] Get Logout settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getLogoutSettings().then((res) => {
      thunkAPI.dispatch(GetLogoutSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);
export const GetPortableDeviceSyncSuccess = createAction<any>(
  "[Settings] Get Portable-Device-Sync settings success"
);
export const SavePortableDeviceSyncSuccess = createAction<any>(
  "[Settings] Save Portable-Device-Sync settings success"
);
export const GetPortableDeviceSync = createAsyncThunk<any, any>(
  "[Settings] Get Portable-Device-Sync settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getPortableDeviceSync()
      .then((res) => {
        thunkAPI.dispatch(GetPortableDeviceSyncSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetPrintingSettingsSuccess = createAction<any>(
  "[Settings] Get Printing settings success"
);
export const SavePrintingSettingsSuccess = createAction<any>(
  "[Settings] Save Printing settings success"
);
export const GetPrintingSettings = createAsyncThunk<any, any>(
  "[Settings] Get Printing settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getPrintingSettings()
      .then((res) => {
        thunkAPI.dispatch(GetPrintingSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetPurchaseOrderSettingsSuccess = createAction<any>(
  "[Settings] Get Purchase Order settings success"
);
export const SavePurchaseOrderSettingsSuccess = createAction<any>(
  "[Settings] Save Purchase Order settings success"
);
export const GetPurchaseOrderSettings = createAsyncThunk<any, any>(
  "[Settings] Get Purchase Order settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getPurchaseOrderSettings()
      .then((res) => {
        thunkAPI.dispatch(GetPurchaseOrderSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));
        return res;
      });
    return result;
  }
);

export const GetPurchaseOrderDeletionReasonSuccess = createAction<any>(
  "[Settings] Get Purchase Order Deletion Reason success"
);
export const SavePurchaseOrderDeletionReasonSuccess = createAction<any>(
  "[Settings] Save Purchase Order Deletion Reason success"
);
export const AddPurchaseOrderDeletionReasonSuccess = createAction<any>(
  "[Settings] Add Purchase Order Deletion Reason success"
);
export const GetPurchaseOrderDeletionReason = createAsyncThunk<any, any>(
  "[Settings] Get Purchase Order Deletion Reason",
  async (_, thunkAPI) => {
    const result = await configurableTypeDataaccess
      .getPurchaseOrderDeletionReasons()
      .then((res) => {
        thunkAPI.dispatch(GetPurchaseOrderDeletionReasonSuccess(res.data));

        return res;
      });
    return result;
  }
);

export const GetProductStockAndFilterSuccess = createAction<any>(
  "[Settings] Get Product-Stock-And-Filter settings success"
);
export const SaveProductStockAndFilterSuccess = createAction<any>(
  "[Settings] Save Product-Stock-And-Filter settings success"
);
export const GetProductStockAndFilter = createAsyncThunk<any, any>(
  "[Settings] Get Product-Stock-And-Filter settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getProductStockAndFilter()
      .then((res) => {
        thunkAPI.dispatch(GetProductStockAndFilterSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetRecipesSettingsSuccess = createAction<any>(
  "[Settings] Get Recipes settings success"
);
export const SaveRecipesSettingsSuccess = createAction<any>(
  "[Settings] Save Recipes settings success"
);
export const SaveSuppliersSettingsSuccess = createAction<any>(
  "[Settings] Save Suppliers settings success"
);
export const GetSupplierSettingsSuccess = createAction<any>(
  "[Settings] Get Supplier settings success"
);
export const GetSuppliersSettings = createAsyncThunk<any, any>(
  "[Settings] Get Supplier settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getSuppliersSettings()
      .then((res) => {
        thunkAPI.dispatch(GetSupplierSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);
export const GetRecipesSettings = createAsyncThunk<any, any>(
  "[Settings] Get Recipes settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getRecipesSettings().then((res) => {
      thunkAPI.dispatch(GetRecipesSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);

export const GetStockCheckSettingsSuccess = createAction<any>(
  "[Settings] Get Stock-Check settings success"
);
export const SaveStockCheckSettingsSuccess = createAction<any>(
  "[Settings] Save Stock-Check settings success"
);
export const GetStockCheckSettings = createAsyncThunk<any, any>(
  "[Settings] Get Stock-Check settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getStockCheckSettings()
      .then((res) => {
        thunkAPI.dispatch(GetStockCheckSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);
export const GetTrainingSettingsSuccess = createAction<any>(
  "[Settings] Get Training settings success"
);
export const SaveTrainingSettingsSuccess = createAction<any>(
  "[Settings] Save Training settings success"
);
export const GetTrainingSettings = createAsyncThunk<any, any>(
  "[Settings] Get Training settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getTrainingSettings()
      .then((res) => {
        thunkAPI.dispatch(GetTrainingSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetWorkRequestNumberSuccess = createAction<any>(
  "[Settings] Get Work-Request-Number settings success"
);
export const SaveWorkRequestNumberSuccess = createAction<any>(
  "[Settings] Save Work-Request-Number settings success"
);
export const GetWorkRequestNumber = createAsyncThunk<any, any>(
  "[Settings] Get Work-Request-Number settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getWorkRequestNumber()
      .then((res) => {
        thunkAPI.dispatch(GetWorkRequestNumberSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetGoodsInSettingsSuccess = createAction<any>(
  "[Settings] Get Goods-In settings success"
);
export const SaveGoodsInSettingsSuccess = createAction<any>(
  "[Settings] Save Goods-In settings success"
);
export const GetGoodsInSettings = createAsyncThunk<any, any>(
  "[Settings] Get Goods-In settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getGoodsInSettings().then((res) => {
      thunkAPI.dispatch(GetGoodsInSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);

export const GetCompanyDetailsSettingsSuccess = createAction<any>(
  "[Settings] Get Company-Details settings success"
);
export const SaveCompanyDetailsSettingsSuccess = createAction<any>(
  "[Settings] Save Company-Details settings success"
);
export const GetCompanyDetailsSettings = createAsyncThunk<any, any>(
  "[Settings] Get Company-Details settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getCompanyDetailsSettings()
      .then((res) => {
        thunkAPI.dispatch(GetCompanyDetailsSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetSalesOrderSettingsSuccess = createAction<any>(
  "[Settings] Get Sales-Order settings success"
);
export const SaveSalesOrderSettingsSuccess = createAction<any>(
  "[Settings] Save Sales-Order settings success"
);
export const GetSalesOrderSettings = createAsyncThunk<any, any>(
  "[Settings] Get Sales-Order settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getSalesOrderSettings()
      .then((res) => {
        thunkAPI.dispatch(GetSalesOrderSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const GetTabletSettingsSuccess = createAction<any>(
  "[Settings] Get Tablet settings success"
);
export const SaveTabletSettingsSuccess = createAction<any>(
  "[Settings] Save Tablet settings success"
);
export const GetTabletSettings = createAsyncThunk<any, any>(
  "[Settings] Get Tablet settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getTabletSettings().then((res) => {
      thunkAPI.dispatch(GetTabletSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));

      return res;
    });
    return result;
  }
);

export const GetVisitorSettingsSuccess = createAction<any>(
  "[Settings] Get Visitor settings success"
);
export const SaveVisitorSettingsSuccess = createAction<any>(
  "[Settings] Save Visitor settings success"
);
export const GetVisitorSettings = createAsyncThunk<any, any>(
  "[Settings] Get Visitor settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getVisitorSettings().then((res) => {
      thunkAPI.dispatch(GetVisitorSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));
      return res;
    });
    return result;
  }
);

export const GetNonConformancesSettingsSuccess = createAction<any>(
  "[Settings] Get Non Conformances settings success"
);
export const SaveNonConformancesSettingsSuccess = createAction<any>(
  "[Settings] Save Non Conformances settings success"
);
export const GetNonConformancesSettings = createAsyncThunk<any, any>(
  "[Settings] Get Non Conformances settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess
      .getNonConformancesSettings()
      .then((res) => {
        thunkAPI.dispatch(GetNonConformancesSettingsSuccess(res.data));
        thunkAPI.dispatch(LoadingData(false));
        return res;
      });
    return result;
  }
);

export const GetReportSettingsSuccess = createAction<any>(
  "[Settings] Get Report settings success"
);
export const SaveReportSettingsSuccess = createAction<any>(
  "[Settings] Save Report settings success"
);
export const GetReportSettings = createAsyncThunk<any, any>(
  "[Settings] Get Report settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await settingsDataAccess.getReportSettings().then((res) => {
      thunkAPI.dispatch(GetReportSettingsSuccess(res.data));
      thunkAPI.dispatch(LoadingData(false));
      return res;
    });
    return result;
  }
);
