import companyDataAccess from "src/dataAccess/companies/companyDataAccess";
import customerItemTypeDataaccess from "src/dataAccess/companies/customerItemType.dataaccess";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ValueLabel } from "src/models/common.models";
import { IListType } from "../store";

export const LoadingData = createAction<boolean>("[Company] Loading data");

export const getCustomerDropdownSuccess = createAction<any>(
  "[Company] Get Supplier Dropdown Data By Type"
);
export const getSupplierDropdownSuccess = createAction<any>(
  "[Company] Get Customer Dropdown Data By Type"
);

export const getCustomerSupplierDropdownByType = createAsyncThunk<
  any,
  "supplier" | "customer"
>(
  "[Company] Get Supplier Customer Dropdown Data By Type",
  async (parameter, thunkAPI) => {
    const result = await companyDataAccess
      .getCompaniesForDropdownByType(parameter)
      .then((res) => {
        let dropdown: ValueLabel[] = res?.data?.map((item) => {
          let data: ValueLabel = {
            value: item.id?.toString(),
            label: item.companyName,
          };
          return data;
        });

        if (parameter === "customer") {
          thunkAPI.dispatch(getCustomerDropdownSuccess(dropdown));
        } else {
          thunkAPI.dispatch(getSupplierDropdownSuccess(dropdown));
        }
        return dropdown;
      });
    return result;
  }
);

export const GetCustomerTypeSuccess = createAction<any>(
  "[Settings] Get Customer-Type settings success"
);
export const GetCustomerType = createAsyncThunk<any, any>(
  "[Settings] Get Customer-Type settings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await customerItemTypeDataaccess
      .getCustomerType()
      .then((res) => {
        thunkAPI.dispatch(GetCustomerTypeSuccess(res));
        thunkAPI.dispatch(LoadingData(false));

        return res;
      });
    return result;
  }
);

export const getSupplierListSuccess = createAction<any>(
  "[Supplier] Get Supplier List Success"
);

export const getSupplierListAsync = createAsyncThunk<any, IListType>(
  "[Supplier] Get Supplier List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await companyDataAccess
      .supplierList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getSupplierListSuccess(res));
        thunkAPI.dispatch(LoadingData(false));
        return res;
      });
    return result;
  }
);
export const getSupplierReviewDateListAsync = createAsyncThunk<any, IListType>(
  "[Supplier] Get Supplier Overdue review date List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await companyDataAccess
      .getSupplierReviewDateList(parameter)
      .then((res) => {
        thunkAPI.dispatch(getSupplierReviewDateListSuccess(res));
        thunkAPI.dispatch(LoadingData(false));
        return res;
      });
    return result;
  }
);
export const getSupplierReviewDateListSuccess = createAction<any>(
  "[Supplier] Get Supplier Overdue review date List Success"
);
export const getSupplierAttachmentInfoSuccess = createAction<any>(
  "[Supplier] Get Supplier Attachment Info Success"
);

export const saveSupplierAttachmentInfoSuccess = createAction<any>(
  "[Supplier] Save Supplier Attachment Info Success"
);

export const getSupplierAttachmentInfo = createAsyncThunk<any, number>(
  "[Supplier] Get Supplier Attachment Info",
  async (supplierId, thunkAPI) => {
    thunkAPI.dispatch(LoadingData(true));
    const result = await companyDataAccess
      .getSupplierAttachmentInfo(supplierId)
      .then((res) => {
        thunkAPI.dispatch(getSupplierAttachmentInfoSuccess(res?.data));
        thunkAPI.dispatch(LoadingData(false));
        return res;
      });
    return result;
  }
);
