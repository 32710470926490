import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IListType } from "../store";
import AccidentDataAccess from "src/dataAccess/accident/AccidentDataAccess";

export const AddPersons = createAction<any>("Add Person Success");
export const getAccidentListSuccess = createAction<any>(
  "[Accident] Get Accident List Success"
);
export const loadingList = createAction<boolean>("[Accident] Loading list");
export const SetPersons = createAction<any>("[Accident] Set Persons");

export const getAccidentListAsync = createAsyncThunk<any, IListType>(
  "[Accident] Get Accident List",
  async (parameter, thunkAPI) => {
    thunkAPI.dispatch(loadingList(true));

    const result = await AccidentDataAccess.AccidentList(parameter).then(
      (res) => {
        thunkAPI.dispatch(getAccidentListSuccess(res));
        thunkAPI.dispatch(loadingList(false));
        return res;
      }
    );
    return result;
  }
);

export const saveAccidentListSuccess = createAction<any>(
  "[Accident] Save Accident Success"
);

export const saveIAccidentListAsync = createAsyncThunk<
  any,
  { AccidentId: any; data: any; AccidentList: Array<any> }
>("[Accident] Save Accident", async (parameter, thunkAPI) => {
  const { AccidentId, data, AccidentList } = parameter;
  thunkAPI.dispatch(loadingList(true));
  var updatedAccidentList;
  if (AccidentId == "edit") {
    let copyAccidentList = [...AccidentList];
    updatedAccidentList = (copyAccidentList ?? []).map((item, index) => {
      let copyItem = { ...item };

      if (copyItem.id == data.id) {
        copyItem = data;
      }
      return copyItem;
    });
  } else {
    let copyAccidentList = [...AccidentList];
    updatedAccidentList = [...copyAccidentList, data];
  }
  thunkAPI.dispatch(saveAccidentListSuccess(updatedAccidentList));
  thunkAPI.dispatch(loadingList(false));
  return data;
});
