//import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "../commonDataAccess";
import { IFilter } from "src/@redux/listRedux";
class peopleDataAccess {
  saveCheckPermission(userCheckIds: string, webUserIds: string, peopleId: any) {
    return commonDataAccess.postData("user/save/permittedChecks", null, {
      id: peopleId,
      userCheckIds: userCheckIds,
      webUserCheckIds: webUserIds,
    });
  }
  getUserDepartmentForDropdown() {
    return commonDataAccess.getData("user/get/users-departments");
  }
  getPermittedChecks(peopleId: any) {
    return commonDataAccess.getData("user/get/permittedChecks", {
      id: peopleId,
    });
  }
  /**
   * Checks if the current token is valid
   * @returns the response sent by api
   */
  GetList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("user/getall", parameters);
  };
  //   alterOrganization = (tenantId) =>  {
  //     return commonDataAccess.postData("Account/altertenant", null, {
  //       tenantId,
  //     })
  //   };
  getUserShifts = () => {
    return commonDataAccess.getData("user/get/usershifts");
  };
  isValidateUser = (id: any, userName: string) => {
    return commonDataAccess.getData("user/validate-user", {
      id: id,
      userName: userName,
    });
  };
  getUsersFromAllOrganizations = () => {
    return commonDataAccess.getData("user/get/allorganizationusers");
  };
  getPeopleInfo = (id: number) => {
    return commonDataAccess.getData("user/get/userinfo", { id: id });
  };
  getPersonInfo = (id: number) => {
    return commonDataAccess.getData("user/get/personinfo", { id: id });
  };
  getPeopleEmailList = () => {
    return commonDataAccess.getData("user/get/users-with-email");
  };
  getPeopleList = () => {
    return commonDataAccess.getData("user/get/users");
  };
  savePeopleInfo = (model: any) => {
    return commonDataAccess.postData("user/save/userinfo", model);
  };
  getContactDetails = (id: number) => {
    return commonDataAccess.getData("user/get/contactDetails", { id: id });
  };
  saveContactDetails = (model: any) => {
    return commonDataAccess.postData("user/save/contactDetails", model);
  };
  getPeoplesDropdown = () => {
    return commonDataAccess.getData("user/get/userlist-dropdown");
  };
  getActiveUsers = () => {
    return commonDataAccess.getData("user/get/activeusers");
  };
  activeuserswithcreatepermission = () => {
    return commonDataAccess.getData("user/get/activeuserswithcreatepermission");
  };
  getTrainers = () => {
    return commonDataAccess.getData("user/get/trainerusers");
  };
  getUserTrainings = (userId) => {
    return commonDataAccess.getData("user/get/trainingrecords", {
      userId: userId,
    });
  };
  getUserHistoryTrainings = (userId) => {
    return commonDataAccess.getData("user/get/history-trainingrecords", {
      userId: userId,
    });
  };
  getUserTrainingCourses = (userId) => {
    return commonDataAccess.getData("user/get/trainingcourses", {
      userId: userId,
    });
  };
  getUserTrainingPack = (userId) => {
    return commonDataAccess.getData("user/get/trainingpacks", {
      userId: userId,
    });
  };
  getTrainingPacksByUser = (userId) => {
    return commonDataAccess.getData("trainingPack/get/TrainingPacksByUser", {
      userId: userId,
    });
  };

  getPasswordInfo = (userId) => {
    return commonDataAccess.getData("user/get/userpasswordinfo", {
      id: userId,
    });
  };
  resetPassword = (model: any) => {
    console.log(model);
    return commonDataAccess.postData("user/resetpassword", model);
  };
  getUserShiftById = (id: number) => {
    return commonDataAccess.getData(`user/get/userShift/${id}`);
  };
  saveUserShift = (payload?: any) => {
    return commonDataAccess.postData("user/save/userShift", payload);
  };
  deleteUserShift = (shiftId: number, peopleId: number) => {
    return commonDataAccess.deleteData("user/delete/userShift", {
      shiftId: shiftId,
      peopleId: peopleId,
    });
  };
  getUsersWithDepartment = () => {
    return commonDataAccess.getData("user/get/userWithDept");
  };
  delete = (ids) => {
    return commonDataAccess.deleteData("user/delete", { ids: ids });
  };
  getUserDropdownByDepartment = (departmentId: any) => {
    return commonDataAccess.getData(`user/get/usernamevalue/${departmentId}`);
  };
  getUserListDropdownByDepartmentId = (departmentId: any) => {
    return commonDataAccess.getData(
      `user/get/user-list/bydepartment/${departmentId}`
    );
  };
  getCurrentUserEmail = () => {
    return commonDataAccess.getData("user/get/currentUserEmail");
  };

  canSendErrorNcToResolve = () => {
    return commonDataAccess.getData("user/get/canSendErrorNcToResolve");
  };

  getAllUserWithEmail = () => {
    return commonDataAccess.getData("user/get/getAllUserWithEmail");
  };
  getUserAndDepartment = () => {
    return commonDataAccess.getData("user/get/userAndDepartment");
  };
  exportDataToExcel = () => {
    return commonDataAccess.downloadFile("user/export/peopleDataToExcel");
  };
  getAllShifts = () => {
    return commonDataAccess.getData("shift/get/allShifts");
  };

  deleteShift = (shiftId: number) => {
    return commonDataAccess.deleteData("shift/delete/shift", {
      shiftId: shiftId,
    });
  };
}
export default new peopleDataAccess();
