import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";
import { formatFilterForQueryParam } from "src/common/service";

class ComplaintCodeDataAccess {
  getList = (params: {
    pageNo;
    pageSize;
    sortData;
    //complaintStatus;
    filters: Array<IFilter>;
  }) => {
    let _filters = formatFilterForQueryParam(params.filters);
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData("complaintCode/getall", parameters);
  };
  getComplaintCodeSelectList = (selectedIds, showInActive) => {
    return commonDataAccess.getData(
      "complaintCode/get/complaintCodeSelectList",
      { selectedIds: selectedIds, showInActive: showInActive }
    );
  };
  getComplaintCode = (id) => {
    return commonDataAccess.getData(`complaintCode/get/complaintCode/${id}`);
  };
  saveComplaintCategory = (payload: any) => {
    return commonDataAccess.postData(
      "complaintCode/save/complaintCategoryWithSubCategories",
      payload
    );
  };
  deleteCategoryById = (id: any) => {
    return commonDataAccess.deleteData(`complaintCode/delete/${id}`);
  };
}

export default new ComplaintCodeDataAccess();
