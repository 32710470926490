import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class AccidentDataAccess {
  saveFirstAiderSignature(payload: { accidentId: any; signature: any }) {
    return commonDataAccess.postData(
      "Accident/save/firstAiderSignature",
      payload
    );
  }

  AccidentList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("Accident/getall", parameters);
  };
  getAccidentInfo = async (params: { id: number | bigint }) => {
    var result = await commonDataAccess.getData(
      "Accident/get/AccidentInfo/" + params,
      {}
    );
    return result;
  };
  getAccidentIssuesDropdowns = () => {
    return commonDataAccess.getData("Accident/get/AccidentIssuesDropdown");
  };
  getAccidentDropdowns = () => {
    return commonDataAccess.getData("Accident/get/AccidentsDropdown");
  };
  saveAccidentInfo = (data: any) => {
    var result = commonDataAccess.postData("Accident/save/AccidentInfo", data);
    return result;
  };
  getAccidentInjuryDetail = (params: { id: number | bigint }) => {
    return commonDataAccess.getData("Accident/get/injurydetails/" + params);
  };
  saveAccidentInjuryDetail = (data: any) => {
    var result = commonDataAccess.postData("Accident/save/injurydetails", data);
    return result;
  };
  getAccidentActionDetail = (params: { id: number | bigint }) => {
    return commonDataAccess.getData("Accident/get/actions/" + params);
  };
  saveAccidentActionDetail = (data: any) => {
    var result = commonDataAccess.postData("Accident/save/actions", data);
    return result;
  };
  deleteAccidentInfo = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("Accident/delete/" + params);
    return result;
  };
}

export default new AccidentDataAccess();
