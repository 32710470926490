import { createReducer } from "@reduxjs/toolkit";
import {
  AddPersons,
  getAccidentListSuccess,
  loadingList,
  saveAccidentListSuccess,
  SetPersons,
} from "./accidentReducer";

export interface IAccidentState {
  AccidentList: Array<any>;
  dataCount: number;
  persons: any[];
  isLoading: boolean;
}

const initialState: IAccidentState = {
  AccidentList: [],
  persons: [],
  dataCount: 0,
  isLoading: false,
};

export const AccidentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAccidentListSuccess, (state, action) => {
      state = {
        ...state,
        AccidentList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(SetPersons, (state, action) => {
      let data = (action.payload ?? []).map((item, index) => {
        let cpy = { ...item };
        cpy["key"] = index + 1;
        return cpy;
      });
      state = {
        ...state,
        persons: data,
      };
      return state;
    })
    .addCase(saveAccidentListSuccess, (state, action) => {
      state = {
        ...state,
        AccidentList: action.payload,
      };
      return state;
    })
    .addCase(AddPersons, (state, action) => {
      let update = {
        ...action.payload,
        key: (state.persons ?? [])?.length + 1,
      };
      // console.log(update);
      state = {
        ...state,
        persons: (state?.persons ?? []).concat(update),
      };
      return state;
    })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
